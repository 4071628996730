<template>
    <div>
      <div class="w-100 pa-4">
        <div class="font-weight-bold primaryColor">Reports
          <v-progress-circular class="ml-2" indeterminate v-if="loading" size="18" :width="2" color="black">
          </v-progress-circular>
        </div>
        <div class="pt-3 d-flex flex-wrap justify-space-between">
          <div>
            <v-slide-group v-model="tabSelect" mandatory>
              <v-slide-item v-for="n in tabs" :key="n" v-slot="{ active, toggle }" :value="n">
                <span @click="$router.push({ path: 'KambalaReports', query: { tab: n } }).catch(() => { })">
                  <v-btn :title="n" depressed height="26" class="fsize12 text-capitalize mr-2" @click="toggle"
                   :color="active ? 'primary' : 'grey lighten-3'">{{ n }}</v-btn>
                </span>
              </v-slide-item>
            </v-slide-group>
          </div>
        </div>
      </div>
      <v-divider class="mx-4"></v-divider>
      <div v-if="tabSelect == 'Counts'">
          <exchCounts/>
      </div>
      <div v-if="tabSelect == 'EDiS'">
        <v-card class="ma-4 cardBoxShadow rounded-lg">
          <edisReport/>
        </v-card>
      </div>
    </div>
  </template>
  <script>
  import { mapGetters } from "vuex";
  import commonFunc from "../../mixins/commonFunctions";
  export default {
     mixins: [commonFunc],
    components: {
      exchCounts: () => import("./exch-counts.vue"),
      edisReport: () => import("./edis.vue")
    },
    data: () => ({
        tabs: ["Counts", "EDiS"],
        tabSelect: "Counts"
    }),
    computed: {
      ...mapGetters({
        loading: "getLoader",
      }),
    },
    created() {
      this.tabSelect = this.$route.query.tab ? this.$route.query.tab : "Payin";
    },
  
    mounted() { },
    methods:{ }
  };
  </script>
  
  